/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.6.0/dist/jquery.min.js
 * - /npm/bootstrap@4.1.3/dist/js/bootstrap.min.js
 * - /npm/smoothscroll@0.4.0/smoothscroll.min.js
 * - /npm/jquery.appear@1.0.1/jquery.appear.min.js
 * - /npm/waypoints@4.0.1/lib/jquery.waypoints.min.js
 * - /npm/jquery.counterup@2.1.0/jquery.counterup.min.js
 * - /npm/counterup2@1.0.4/dist/index.min.js
 * - /npm/jquery.easing@1.4.1/jquery.easing.min.js
 * - /npm/jquery-knob@1.2.11/dist/jquery.knob.min.js
 * - /npm/owl.carousel@2.3.4/dist/owl.carousel.min.js
 * - /npm/magnific-popup@1.1.0/dist/jquery.magnific-popup.min.js
 * - /npm/mailcheck@1.1.1/src/mailcheck.min.js
 * - /npm/jquery-autogrow-textarea@1.2.1/dist/jquery.autogrow.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
